import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import loadable from "@loadable/component";

import { PageLinks } from "../../../common/site/page-static-links";
import FormFields from "../../../forms_config/team_contact_form.json";

import LayoutTwo from "../../../components/layoutTwo";
import Seo from "../../../components/seo"
import StaticIntro from "../../../components/StaticIntro/StaticIntro";


const BreadcrumbModule = loadable(() => import("../../../components/BreadcrumbModule/BreadcrumbModule"));
const ValuationContact = loadable(() => import("../../../components/ValuationContact/ValuationContact"));
const TeamForm = loadable(() => import("../../../components/forms/default-form-layout"));

const TeamContact = (props) => {

    const name = props.location?.state?.name
    const email = props.location?.state?.email

    let breadcrumData;

    if (PageLinks?.team_parent_label) {
        breadcrumData = { parentlabel: PageLinks.team_parent_label, parentlink: PageLinks.team_parent, subparentlabel: PageLinks.team_label, subparentlink: PageLinks.team, title: `Contact ${name ? name : ''}` }
    } else {
        breadcrumData = { parentlabel: PageLinks.team_label, parentlink: PageLinks.team, title: `Contact ${name ? name : ''}` }
    }

    return (
        <LayoutTwo>
            <Seo title={`Contact ${name}`} />
            <div className="layout-padding-top">
                <BreadcrumbModule {...breadcrumData} />
                <StaticIntro title={`Contact ${name ? name : ''}`} />
                <Container>
                    <Row>
                        <Col xl={7}>
                            <div className="d-md-none">
                                <ValuationContact tag="inner-form" />
                            </div>
                            <div className="inner-form-wrapper">
                                <TeamForm to_email_id={email ? email : ''} title={name ? name : ''} fields={FormFields} classname="enquiry-form-wrapper" />
                            </div>
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={4} className="d-md-block d-none">
                            <ValuationContact tag="inner-form" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </LayoutTwo>
    )
}


export default TeamContact